/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./index.scss";
import { deleteComment } from "store/modules/jobs/actions";
import { store } from "store";
import autosize from "autosize";
import { useTranslation } from "react-i18next";
import { Check, Close } from "mixins/svgIcons";
import { Delete, EditPencil } from "../../svgIcons";

const getAvatarName = (userName, email) => {
  if (userName) {
    return userName
    // eslint-disable-next-line no-return-assign
      ? userName.split(/\s/).reduce((name, word) => name += word.slice(0, 1), "")
      : "";
  }

  return email.slice(0, 1);
};

const computeTime = date => {
  const now = moment();
  const givenDate = moment(date);
  const diffInSeconds = now.diff(givenDate, "seconds");
  const diffInMinutes = now.diff(givenDate, "minutes");
  const diffInHours = now.diff(givenDate, "hours");
  const diffInDays = now.diff(givenDate, "days");
  const diffInWeeks = now.diff(givenDate, "weeks");

  if (diffInSeconds < 60) {
    return "few sec ago";
  } if (diffInMinutes < 60) {
    return `${diffInMinutes } mins ago`;
  } if (diffInHours < 24) {
    return `${diffInHours } hrs ago`;
  } if (diffInDays < 7) {
    return `${diffInDays } days ago`;
  } if (diffInWeeks < 1) {
    return `${diffInWeeks } weeks ago`;
  }
  return givenDate.fromNow();
};
const CandidateSidebarCommentItem = ({ comment }) => {
  const userId = store.getState()?.jobsCandidate?.currentCandidateJob?.key;
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState(comment.text);
  const textarea = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    autosize(textarea.current);
  }, [textValue]);

  const saveComment = event => {
    event.preventDefault();
    setTextValue("");
  };

  const handleComment = e => {
    const str = e.target.value.replace(/\n/g, "");

    setTextValue(str);
  };
  const handleKeyDown = e => {
    if ([37, 39].includes(e.keyCode)) {
      e.stopPropagation();
    }
  };

  if (isEditing) {
    return (
      <div>
        <form onSubmit={saveComment}>
          <textarea
            id="comment"
            type="text"
            name="comment"
            row="1"
            ref={textarea}
            value={textValue}
            className="candidate__comment-textarea"
            placeholder={t("input.candidateCommentPlaceholder")}
            onChange={handleComment}
            onKeyDown={handleKeyDown}
          />
          <div className="form__add-comment-submit">
            <div>
              <Check />
              <Close />
            </div>
          </div>
        </form>
      </div>
    );
  }
  return (
    <div
      className="candidate__comment-item"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        className="users__user-list-item-info-avatar candidate__comment-item-avatar"
      >
        <p className="n-font-small n-font-medium-weight n-white n-uppercase">{getAvatarName(comment.author_name, comment.author_email)}</p>
      </div>
      <div className="candidate__comment-container">
        <div className="candidate__comment-header">
          <div>
            {comment.author_name}
          </div>
          {
            isMouseOver ? (
              <div className="candidate__comment-icons">
                <div onClick={() => {
                  setIsEditing(true);
                }}
                >
                  <EditPencil fill="#59595980" />
                </div>
                <div onClick={() => {
                  store.dispatch(deleteComment({
                    userId,
                    commentId: comment.key
                  }));
                }}
                >
                  <Delete fill="#59595980" />
                </div>
              </div>
            ) : (
              <div>
                {computeTime(comment.updated_at)}
              </div>
            )
          }
        </div>
        <div className="candidate__comment-text">
          {comment.text}
        </div>
      </div>
    </div>
  );
};

CandidateSidebarCommentItem.propTypes = {
  comment: PropTypes.shape({
    author_email: PropTypes.string,
    author_name: PropTypes.string,
    key: PropTypes.string,
    text: PropTypes.string,
    updated_at: PropTypes.string
  }).isRequired
};

export default CandidateSidebarCommentItem;
