/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Confetti from "react-confetti";

import "./styles.scss";
import { handleStatus } from "mixins/helperCandidate";
import CANDIDATE_STATUSES from "configs/candidates";
import { rejectDetailsConfirmation, shareModalConfirmation } from "utils/confirm";
import { ShareCandidateIcon } from "mixins/svgIcons";
import TooltipInfo from "components/Common/Tooltip/Info";
import useWindowResize from "hooks/useWindowResize.tsx";
import { Accept, Delete, Reject, Remind } from "../svgIcons";

const CandidateButtons = ({
  t,
  userId,
  jobId,
  jobCandidateInfo: {
    name = "",
    email = "",
    status = "",
    allow_reminder_sending: allowReminderSending
  },
  currentUser: { key: agency },
  jobCurrent,
  isAdmin,
  profileInfo,
  sharableLink = "",
  showcaseLinkOpenedAmount,
  isShowcaseLinkEnabled,
  answers,
  shareCandidatePublic,
  clearSharableLink,
  updateCandidateStatus,
  sendReminderCandidate,
  setAvailableAnswer,
  setAvailableLink,
  deleteCandidate,
  moveToNext,
  contentRef,
  hasReminder,
  handleMoveToNextAfterAccept,
  isLoading
}) => {
  const acceptRef = useRef(null);
  const rejectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [run, setRun] = useState(false);
  const [isDeleteButtonVisible, setDeleteButtonVisible] = useState(true);

  const { isMobileAndTabletScreen } = useWindowResize();

  const candidateStatus = handleStatus(status);

  const toggleDropdown = (e, value) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(prev => (prev === value ? null : value));
  };

  const callback = () => setRun(true);

  const handleReject = useCallback(async () => {
    const reason = await rejectDetailsConfirmation();
    await updateCandidateStatus({
      name,
      email,
      userId,
      status: CANDIDATE_STATUSES.REJECTED,
      agency,
      jobId,
      ...reason
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCandidateStatus, name, email, userId, agency]);

  const rejectAndMove = async e => {
    e.stopPropagation();

    const reason = await rejectDetailsConfirmation();
    await updateCandidateStatus({
      name,
      email,
      userId,
      status: CANDIDATE_STATUSES.REJECTED,
      agency,
      jobId,
      callback: handleMoveToNextAfterAccept,
      ...reason
    });
  };

  const handleAccept = useCallback(
    async (withCallback = true) => {
      await updateCandidateStatus({
        name,
        email,
        userId,
        status: CANDIDATE_STATUSES.ACCEPTED,
        isAccept: true,
        agency,
        jobId,
        callback: withCallback ? callback : null
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCandidateStatus, name, email, userId, agency]
  );

  const acceptAndMove = async e => {
    e.stopPropagation();

    await updateCandidateStatus({
      name,
      email,
      userId,
      status: CANDIDATE_STATUSES.ACCEPTED,
      isAccept: true,
      agency,
      jobId,
      callback: handleMoveToNextAfterAccept
    });
  };

  const shareCandidateHandler = () => shareCandidatePublic({ userId, jobId });

  const handleAvailableAnswer = params =>
    setAvailableAnswer({
      ...params,
      jobId,
      userId
    });

  const handleAvailableLink = available =>
    setAvailableLink({
      available,
      jobId,
      userId
    });

  const handleSendReminder = () => {
    sendReminderCandidate({ userId, jobId });
  };

  const handleHideDelete = useCallback(() => {
    setDeleteButtonVisible(false);
    setTimeout(() => setDeleteButtonVisible(true), 5000);
  }, []);

  const handleDelete = useCallback(
    () => deleteCandidate({ userId, jobId, agency }),
    [deleteCandidate, userId, jobId, agency]
  );

  const closeAcceptDropdown = useCallback(e => {
    if (acceptRef.current && !acceptRef.current.contains(e.target) && isOpen === "accept") {
      setIsOpen(null);
    }
  }, [isOpen]);

  const closeRejectDropdown = useCallback(e => {
    if (rejectRef.current && !rejectRef.current.contains(e.target) && isOpen === "reject") {
      setIsOpen(null);
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", closeAcceptDropdown);
    document.addEventListener("mousedown", closeRejectDropdown);
    return () => {
      document.removeEventListener("mousedown", closeAcceptDropdown);
      document.removeEventListener("mousedown", closeRejectDropdown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (sharableLink) {
      shareModalConfirmation(name, {
        sharableLink,
        clearSharableLink,
        setAvailableAnswer: handleAvailableAnswer,
        setAvailableLink: handleAvailableLink,
        answers,
        showcaseLinkOpenedAmount,
        isShowcaseLinkEnabled
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSharableLink, name, sharableLink]);

  const isAccepted = candidateStatus === "accepted";
  const isRejected = candidateStatus === "rejected";

  if (isLoading) return null;

  return (
    <div className="candidate__buttons-wrapper">

      { status === CANDIDATE_STATUSES.INVITED ? (
        <>
          <TooltipInfo
            element={null}
            position="bottom"
            message={t(
              jobCurrent?.status === "closed"
                ? "job.overview.reminderDisabled"
                : hasReminder
                  ? "job.overview.reminderTooltip"
                  : "job.overview.firstReminderTooltip"
            )}
            disabled={allowReminderSending}
          >
            <div className="wrap-button">
              <button
                className={`n-border-black-5 n-bg-black-5 n-black-50 n-font-small share-button ${
                  !allowReminderSending ? "disabled" : ""
                }`}
                type="button"
                onClick={allowReminderSending && handleSendReminder}
                disabled={!allowReminderSending}
              >
                <Remind />
                <span>{t("button.sendReminder")}</span>
              </button>
            </div>
          </TooltipInfo>
          {isAdmin || profileInfo.email === jobCurrent.created_by_email ? (
            <button
              className="n-border-red-200 n-red-reject n-white n-font-small delete-button"
              type="button"
              onClick={isDeleteButtonVisible ? handleHideDelete : handleDelete}
            >
              <Delete />
              <span>
                {t(
                  isDeleteButtonVisible
                    ? "button.delete"
                    : "job.candidate.really"
                )}
              </span>
            </button>
          ) : null}
        </>
      ) : (
        <>
          {
            (isAdmin || profileInfo.email === jobCurrent.created_by_email) ? (
              <>
                <div
                  className="candidate__dropdown-button-wrapper flex items-center justify-center accept"
                  ref={acceptRef}
                >
                  <button
                    type="button"
                    className={`dropdown-button flex items-center justify-around
                      ${isAccepted ? "accepted" : "accept"}
                      ${(isAccepted || isMobileAndTabletScreen || !moveToNext) ? "" : "has-dropdown"}
                    `}
                    onClick={!isAccepted && handleAccept}
                  >
                    <Accept height="20" width="20" />

                    <span className="btn-label">
                      {t(
                        isAccepted
                          ? "button.accepted"
                          : "button.accept"
                      )}
                    </span>
                  </button>
                  <button
                    type="button"
                    className={`dropdown-button-right flex justify-center items-center accept
                      ${(isAccepted || isMobileAndTabletScreen || !moveToNext) ? "hide" : "display"}
                    `}
                    onClick={e => toggleDropdown(e, "accept")}
                    onKeyDown={e => {
                      if (e.code === "Enter" || e.key === "Enter") {
                        toggleDropdown(e, "accept");
                      }
                    }}
                  >
                    { !isAccepted
                      ? (
                        <span>
                          {isOpen === "accept" ? <FaChevronUp size="14" /> : <FaChevronDown size="14" />}
                        </span>
                      ) : null }
                  </button>
                  {isOpen === "accept" && moveToNext && (
                    <div
                      className="dropdown-content"
                      onClick={acceptAndMove}
                      onKeyDown={e => {
                        if (e.code === "Enter" || e.key === "Enter") {
                          acceptAndMove(e);
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {t("button.acceptAndMove")}
                    </div>
                  )}
                </div>

                <div
                  className="candidate__dropdown-button-wrapper flex items-center justify-center reject"
                  ref={rejectRef}
                >
                  <button
                    type="button"
                    className={`dropdown-button flex items-center
                      ${isRejected ? "rejected" : "reject"}
                      ${(isRejected || isMobileAndTabletScreen || !moveToNext) ? "" : "has-dropdown"}
                    `}
                    onClick={!isRejected && handleReject}
                  >
                    <Reject height="20" width="20" />

                    <span className="btn-label">
                      {t(
                        isRejected
                          ? "button.rejected"
                          : "button.reject"
                      )}
                    </span>
                  </button>
                  <button
                    type="button"
                    className={`dropdown-button-right flex justify-center items-center reject
                      ${(isRejected || isMobileAndTabletScreen || !moveToNext) ? "hide" : "display"}
                    `}
                    onClick={e => toggleDropdown(e, "reject")}
                    onKeyDown={e => {
                      if (e.code === "Enter" || e.key === "Enter") {
                        toggleDropdown(e, "reject");
                      }
                    }}
                  >
                    { !isRejected
                      ? (
                        <span>
                          {isOpen === "reject" ? <FaChevronUp size="14" /> : <FaChevronDown size="14" />}
                        </span>
                      ) : null }
                  </button>
                  {isOpen === "reject" && moveToNext && (
                    <div
                      className="dropdown-content"
                      onClick={rejectAndMove}
                      onKeyDown={e => {
                        if (e.code === "Enter" || e.key === "Enter") {
                          rejectAndMove(e);
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {t("button.rejectAndMove")}
                    </div>
                  )}
                </div>
              </>
            ) : null
          }

          <button
            className="share-button flex items-center justify-center"
            type="button"
            onClick={shareCandidateHandler}
          >
            <ShareCandidateIcon />
            <span>{t("button.share")}</span>
          </button>
        </>
      )}

      {run ? (
        <Confetti
          numberOfPieces={500}
          width={contentRef.current?.offsetWidth || window.innerWidth}
          height={contentRef.current?.offsetHeight || window.innerHeight}
          recycle={false}
          onConfettiComplete={() => setRun(false)}
        />
      ) : null}
    </div>
  );
};

export default withTranslation()(CandidateButtons);
