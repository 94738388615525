import React, { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { store } from "store";
import { sendComment } from "store/modules/jobs/actions";

const CandidateSidebarAddComment = () => {
  const userId = store.getState()?.jobsCandidate?.currentCandidateJob?.key;
  const textarea = useRef(null);
  const [value, setValue] = useState("");


  const saveComment = event => {
    event.preventDefault();
    store.dispatch(sendComment({ userId, text: event.target[0].value }));
    setValue("");
  };

  const handleComment = e => {
    const str = e.target.value.replace(/\n/g, "");

    setValue(str);
  };
  const handleKeyDown = e => {
    if ([37, 39].includes(e.keyCode)) {
      e.stopPropagation();
    }
  };

  return (
    <div>
      <form onSubmit={saveComment}>
        <textarea
          id="comment"
          type="text"
          name="comment"
          row="1"
          ref={textarea}
          value={value}
          className="candidate__comment-textarea"
          placeholder="Write a comment"
          onChange={handleComment}
          onKeyDown={handleKeyDown}
        />
        <div className="form__add-comment-submit">
          <button
            disabled={!value?.trim()}
            className="n-button__small n-bg-purple-100 n-white n-font-semi-bold w-full mb-4"
            type="submit"
          >
            Add Comment
          </button>
        </div>
      </form>
    </div>
  );
};


export default withTranslation()(CandidateSidebarAddComment);
