/* eslint-disable no-nested-ternary */
import React, { useMemo, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCandidateIdvResponse, getJobCurrent, getUserCurrentJob } from "store/modules/jobs/selectors";
import moment from "moment";
import { DATE_FORMATS } from "configs/dates";
import { IDV_SKIPPED } from "configs/jobs/constants";
import { getRequestWithToken } from "sagas/api";
import { getToken } from "store/modules/auth/selectors";
import downloadFile from "utils/downloadFile";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import IDVDocument from "./IDVDocument";

import "./styles.scss";
import IDVIdentity from "./IDVIdentity";
import IDVShareCode from "./IDVShareCode";
import RTWCheck from "./RTWCheck";
import DBSCheck from "./DBSCheck";

const CandidateAnswerIDV = ({
  idvCheckData,
  token,
  currentQuestionAnswer,
  candidateInfo,
  currentJob
}) => {
  const ref = useRef(null);

  const pdfFileName = `${candidateInfo?.name} Digital Identity Check - ${currentJob?.title}.pdf`;

  countries.registerLocale(english);
  const alpha2code = countries.alpha3ToAlpha2(
    idvCheckData?.documents?.[0]?.issuing_country,
    "en"
  );
  const countryName = countries.getName(alpha2code, "en");

  const date = moment(idvCheckData?.identity?.created_at).format(
    DATE_FORMATS.SPACE_SEPARATOR_MONTH_FIRST
  );

  const handleSelectText = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStartBefore(ref.current);
    range.setEndAfter(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleDownloadClick = async (type, index) => {
    const response = await getRequestWithToken({
      url: `/api/idv/${type}/media/${
        type === "document"
          ? idvCheckData?.documents[index]?.images_id[0]
          : idvCheckData?.identity?.image_id
      }/`,
      token,
      responseType: "arraybuffer"
    });

    if (response.status === 200) {
      const buffer = new Uint8Array(response.data);
      const blob = new Blob([buffer], { type: "image/jpeg" });
      const imgUrl = window.URL.createObjectURL(blob);

      downloadFile(imgUrl);
    }
  };

  const isOverAllStatusSkipped = useMemo(() => idvCheckData?.overall_status === IDV_SKIPPED,
    [idvCheckData]);

  const isIdvSkipped = useMemo(() => idvCheckData?.is_idv_skipped ||
    isOverAllStatusSkipped, [idvCheckData, isOverAllStatusSkipped]);

  return (
    <>
      <div className="candidateanswer__section__response-idv response" onDoubleClick={handleSelectText}>
        {
          idvCheckData?.error ? (
            <div className="candidateanswer__section__response-idv__error">
              {idvCheckData?.error}
            </div>
          ) : null }

        <div className="answer-scroll">
          <div className="candidateanswer__section__response-idv__response-wrapper" ref={ref}>
            <IDVDocument
              handleDownloadClick={handleDownloadClick}
              idvCheckData={idvCheckData}
              isIdvSkipped={isIdvSkipped}
              countryName={countryName}
            />

            <IDVIdentity
              handleDownloadClick={handleDownloadClick}
              idvCheckData={idvCheckData}
              isIdvSkipped={isIdvSkipped}
              countryName={countryName}
              date={date}
            />

            <RTWCheck
              idvCheckData={idvCheckData}
            />

            <DBSCheck
              idvCheckData={idvCheckData}
              isIdvSkipped={isIdvSkipped}
            />

            <IDVShareCode
              idvCheckData={idvCheckData}
              currentQuestionAnswer={currentQuestionAnswer}
              isOverAllStatusSkipped={isOverAllStatusSkipped}
            />
          </div>
        </div>


        {idvCheckData?.pdfUrl ? (
          <div className="idv-check__pdf">
            <p>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={idvCheckData.pdfUrl}
                download={pdfFileName}
              >
                Download PDF report
              </a>
            </p>
          </div>
        ) : null}
      </div>
      <div className="candidateanswer__floatingbackground" />
    </>
  );
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  candidateInfo: getUserCurrentJob(state),
  currentJob: getJobCurrent(state),
  idvCheckData: getCandidateIdvResponse(state),
  token: getToken(state)
});

CandidateAnswerIDV.defaultProps = {
  token: undefined,
  currentQuestionAnswer: {},
  idvCheckData: {},
  candidateInfo: {},
  currentJob: {}
};

CandidateAnswerIDV.propTypes = {
  token: PropTypes.string,
  currentQuestionAnswer: PropTypes.shape({
    question: PropTypes.shape({
      order: PropTypes.number
    }),
    idv_citizenship_iso_code: PropTypes.string
  }),
  idvCheckData: PropTypes.shape({
    isLoading: PropTypes.bool,
    overall_status: PropTypes.string,
    is_idv_skipped: PropTypes.bool,
    error: PropTypes.string,
    represent_as: PropTypes.string,
    documents: PropTypes.shape([
      PropTypes.shape({
        issuing_country: PropTypes.string
      })
    ]),
    identity: PropTypes.shape({
      created_at: PropTypes.string,
      rejection_reason: PropTypes.string,
      image_id: PropTypes.string
    }),
    right_to_work: PropTypes.shape({
      share_code: PropTypes.string
    }),
    pdfUrl: PropTypes.string
  }),
  candidateInfo: PropTypes.shape({
    name: PropTypes.string
  }),
  currentJob: PropTypes.shape({
    title: PropTypes.string
  })
};

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerIDV));
