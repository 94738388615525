/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import TooltipInfo from "components/Common/Tooltip/Info";
import { CopyTable } from "mixins/svgIcons";
import { handleStatus } from "mixins/helperCandidate";

import "./styles.scss";
import { getDiffDateToday } from "mixins/helperDateConversion";
import { connect } from "react-redux";
import { getCandidateScores } from "store/modules/scorecards/selectors";
import { copyToClipboard } from "mixins/helpers";
import Copy from "components/Tables/Jobs/Buttons/Copy";
import { size } from "lodash";
import useWindowResize from "hooks/useWindowResize.tsx";
import SkeletonWrapper from "../../../containers/Hoc/SkeletonWrapper";
import CandidateButtons from "./CandidateButtons/CandidateButtons";
import InterviewScorecardPill from "./InterviewScorecardPill";

const NAME = "name";

const Overview = ({
  t,
  jobCandidateInfo,
  jobCurrent,
  history: vHistory,
  userId,
  jobId,
  currentUser,
  isAdmin,
  profileInfo,
  sharableLink = "",
  showcaseLinkOpenedAmount,
  isShowcaseLinkEnabled,
  shareCandidatePublic,
  clearSharableLink,
  updateCandidateStatus,
  sendReminderCandidate,
  setAvailableAnswer,
  setAvailableLink,
  deleteCandidate,
  contentRef,
  handleMoveToNextAfterAccept,
  handleGoToScorecard,
  answers,
  hasReminder,
  moveToNext,
  scores,
  rating,
  scorecardTotal,
  isLoading
}) => {
  const {
    name = "",
    status = "",
    updated_at: updatedAt = "",
    anonymized_at: anonymizedAt
  } = jobCandidateInfo;
  const condidateStatus = handleStatus(status);

  const message = getDiffDateToday(t, updatedAt, vHistory);

  const [hoveredItem, setHoveredItem] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const [isMouseOverCopy, setIsMouseOverCopy] = useState(false);
  const { isMobileAndTabletScreen } = useWindowResize();

  const handleCopy = val => {
    setShowTooltip(true);
    copyToClipboard(val);

    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  return (
    <div className="candidate-overview__header-info">
      <div className="candidate-overview__header-box">
        <SkeletonWrapper
          skeletonProps={{ count: 3, enableAnimation: !anonymizedAt }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: isMobileAndTabletScreen ? "100%" : 300,
              paddingTop: isMobileAndTabletScreen ? 16 : 10
            }
          }}
          anonymizedDate={anonymizedAt}
          isLoading={isLoading}
        >
          <>
            <div className="flex w-full flex-col">
              <div className="candidate-overview__header-box-item">
                <TooltipInfo
                  message={message}
                  element={null}
                  tooltipPrefix="tooltip-right__custom"
                  position="right"
                >
                  <span className="candidate-overview__header-box-status n-font-extra-small n-font-light n-grey-100">
                    {condidateStatus ? t(`widgets.progressSend.${condidateStatus}`) : null}
                  </span>
                </TooltipInfo>

                {(size(scores) > 0) && !isLoading ? (
                  <div
                    style={{ marginRight: (isMobileAndTabletScreen) ? "auto" : 15, marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => handleGoToScorecard?.()}
                  >
                    <TooltipInfo
                      prefix="tooltip__icon tooltip__icon-people without-styles align-center"
                      position="left"
                      message={
                      rating?.untranslated_label
                        ? t(`scorecard.people.rating.${rating?.untranslated_label ?? ""}`)
                        : null
                      }
                      disabled={!rating?.untranslated_label}
                    >
                      <SkeletonWrapper
                        skeletonProps={{
                          width: 100,
                          height: 36,
                          borderRadius: 30,
                          marginTop: 0

                        }}
                        isLoading={isLoading}
                      >
                        <InterviewScorecardPill
                          score={scorecardTotal?.general_score}
                          fontSize={12}
                          total={size(scores)}
                          iconWidth={16}
                          iconHeight={16}
                          buttonHeight={27}
                          iconOnly={isMobileAndTabletScreen}
                          customStyles={{ padding: "2px 8px" }}
                        />
                      </SkeletonWrapper>
                    </TooltipInfo>
                  </div>
                ) : null}
              </div>

              <div
                className="candidate-overview__header-box-container"
                onMouseEnter={() => setHoveredItem(NAME)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <p className="candidate-overview__header-box-name n-font-semi-bold n-black-80">
                  {name}
                </p>

                {
                  (!isMobileAndTabletScreen) && (
                    <Copy
                      handleCopy={() => handleCopy(name)}
                      isCopied={showTooltip}
                      copyBtn={<CopyTable
                        style={{ display: hoveredItem === NAME ? "inline-block" : "none" }}
                        svgIconClass="not-clickable-icon"
                        fill={isMouseOverCopy ? "#5A2AF1" : undefined}
                        onMouseEnter={() => setIsMouseOverCopy(true)}
                        onMouseLeave={() => setIsMouseOverCopy(false)}
                      />}
                      btnStyles={{ paddingLeft: 5, paddingRight: 0 }}
                    />
                  )
                }
              </div>


            </div>

            <p style={{
              marginTop: 10,
              textAlign: (isMobileAndTabletScreen) ? "center" : "",
              margin: (isMobileAndTabletScreen) ? "0px auto" : ""
            }}
            >
              <Link
                className="candidate-overview__header-box-link"
                to={`/jobs/overview/${jobCurrent.key}`}
              >
                {jobCurrent.title}
              </Link>
              <span>{t("job.candidate.for")}</span>
              <Link
                className="candidate-overview__header-box-link"
                to={`/companies/overview/${jobCurrent.company?.key}`}
              >
                {jobCurrent.company?.name}
              </Link>
            </p>

            {
              !anonymizedAt ? (
                <CandidateButtons
                  userId={userId}
                  jobId={jobId}
                  jobCandidateInfo={jobCandidateInfo}
                  currentUser={currentUser}
                  jobCurrent={jobCurrent}
                  isAdmin={isAdmin}
                  profileInfo={profileInfo}
                  sharableLink={sharableLink}
                  showcaseLinkOpenedAmount={showcaseLinkOpenedAmount}
                  isShowcaseLinkEnabled={isShowcaseLinkEnabled}
                  answers={answers}
                  shareCandidatePublic={shareCandidatePublic}
                  clearSharableLink={clearSharableLink}
                  updateCandidateStatus={updateCandidateStatus}
                  sendReminderCandidate={sendReminderCandidate}
                  setAvailableAnswer={setAvailableAnswer}
                  setAvailableLink={setAvailableLink}
                  deleteCandidate={deleteCandidate}
                  contentRef={contentRef}
                  isLoading={isLoading}
                  moveToNext={moveToNext}
                  handleMoveToNextAfterAccept={handleMoveToNextAfterAccept}
                  hasReminder={hasReminder}
                  handleGoToScorecard={handleGoToScorecard}
                />
              ) : null
            }
          </>
        </SkeletonWrapper>
      </div>
    </div>
  );
};

Overview.defaultProps = {
  currentUser: undefined
};

Overview.propTypes = {
  t: PropTypes.func.isRequired,
  jobCandidateInfo: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.string,
    rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    allow_reminder_sending: PropTypes.bool,
    avatar_remote_link: PropTypes.string,
    anonymized_at: PropTypes.string
  }).isRequired,
  jobCurrent: PropTypes.shape({
    created_by_email: PropTypes.string,
    title: PropTypes.string,
    key: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      action_type: PropTypes.string,
      created_at: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }),
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  candidateScores: getCandidateScores(state)
});
export default connect(mapStateToProps)(withTranslation()(Overview));
