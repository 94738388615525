/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Reload, UploadIcon } from "mixins/svgIcons";
import AnswerHeader from "components/Common/AnswerHeader";

import UnsafeModal from "components/Answer/List/File/UnsafeModal";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import formatBytes from "../helpers";

const Item = ({
  t,
  history,
  jobId = "",
  userId = "",
  question = {},
  questionNumber = "",
  withButton = true,
  size = "",
  textSize = "",
  retakeAnswerAfterFileUpload,
  answer,
  questionTextProps,
  memorizedCompanyInfo,
  thinkingTime,
  spentTime,
  currentQuestion,
  status,
  isJobCandidateLoading,
  isShowcaseLoading
}) => {
  const [showUnsafeModal, setshowUnsafeModal] = useState(false);
  const isCandidate = window.location.href.includes(
    "candidate/video-questions/preview"
  );
  const isPublic =
    window.location.href.includes("bulk-public-candidates") ||
    window.location.href.includes("public-candidate");

  const returnCreateQuestion = useCallback(() => {
    retakeAnswerAfterFileUpload({ userId, questionKey: question.key });

    history.push({
      pathname: `/candidate/video-questions/create/${userId}/${jobId}/${question.key}`
    });
  }, []);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = answer.remote_link;
    a.download = `${answer.friendly_name}.${answer.media_extension}`;
    document.body.appendChild(a);
    a.click();
    if (document.body.contains(a)) {
      document.body.removeChild(a);
    }
  };

  const handleClickDownload = () => setshowUnsafeModal(true);

  if (status === "unsafe" && !isCandidate) {
    return (
      <div className="video__preview-item">
        <AnswerHeader
          questionNumber={questionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
          textSize={textSize}
          questionTextProps={questionTextProps}
          questionText={question?.text}
          currentQuestion={currentQuestion}
        />

        <SkeletonWrapper
          skeletonProps={{
            count: 1,
            enableAnimation: true,
            height: "30vh",
            borderRadius: 20
          }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: "100%"
            }
          }}
          isLoading={isJobCandidateLoading || isShowcaseLoading}
        >
          <div className={`dzu-dropzone ${size}`}>
            <div className="dzu-dropzone__unsafe">
              <div className={`${size} dzu-dropzone__unsafe-text-container`}>
                <div className="dzu-dropzone__unsafe-text">
                  {t("candidate.videoCreate.mediaUnsafe")}
                </div>
                <div className="dzu-dropzone__unsafe-content">
                  <div className="dzu-dropzone__unsafe-text">
                    {t("notFound.contact")}
                  </div>
                  <div className="dzu-dropzone__unsafe-email">
                    support@willo.video
                  </div>
                  <div className="dzu-dropzone__unsafe-text">
                    {t("notFound.moreInfo")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SkeletonWrapper>
      </div>
    );
  }
  if (status === "received" && !isCandidate) {
    return (
      <div className="video__preview-item">
        <AnswerHeader
          questionNumber={questionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
          textSize={textSize}
          questionTextProps={questionTextProps}
          questionText={question?.text}
          currentQuestion={currentQuestion}
        />

        <SkeletonWrapper
          skeletonProps={{
            count: 1,
            enableAnimation: true,
            height: "30vh",
            borderRadius: 20
          }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: "100%"
            }
          }}
          isLoading={isJobCandidateLoading || isShowcaseLoading}
        >
          <div className={`dzu-dropzone ${size}`}>
            <div className="dzu-dropzone__unsafe">
              <div className="dzu-dropzone__unsafe-text-container">
                <div className="dzu-dropzone__unsafe-text">
                  <strong>{t("unsafeNotice.scanning")}</strong>
                </div>
              </div>
            </div>
          </div>
        </SkeletonWrapper>
      </div>
    );
  }

  return (
    <div className="video__preview-item">
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={textSize}
        questionTextProps={questionTextProps}
        questionText={question?.text}
        currentQuestion={currentQuestion}
      />
      {showUnsafeModal && (
        <UnsafeModal
          onDownload={handleDownload}
          handleCloseModal={() => setshowUnsafeModal(false)}
          withToggle={!isPublic}
        />
      )}

      <div className={`dzu-dropzone ${size}`}>
        <div className="dzu-previewContainer">
          <div className="dzu-previewFileNameContainer">
            <div className="dzu-iconFile">
              <UploadIcon
                svgIconClass="not-clickable-icon"
                width="40"
                height="30"
                fill="#595959"
              />
            </div>
            <div className="dzu-previewFileNameIcon">
              <span className="dzu-previewFileName">{`${answer.friendly_name}.${answer.media_extension}`}</span>
            </div>
          </div>
          <div className="dzu-removeButtonContainer">
            <a className="dzu-removeButton view" onClick={handleClickDownload}>
              {t("button.view")}
            </a>
          </div>
          <div className={`dzu-percentContainer ${size}`}>
            <span className="dzu-percent">{formatBytes(answer.size || 0)}</span>
          </div>
        </div>
      </div>

      {withButton && (
        <div className="video__preview-button">
          <button
            type="button"
            className="
              n-button__large-border
              n-border-purple-100
              n-purple-100
            "
            onClick={returnCreateQuestion}
            style={
              memorizedCompanyInfo && {
                borderColor: memorizedCompanyInfo.button_color,
                color: memorizedCompanyInfo.button_color
              }
            }
          >
            <Reload
              svgIconClass="n-icon__large right"
              fill={memorizedCompanyInfo?.button_color}
            />
            {t("button.reTake")}
          </button>
        </div>
      )}
    </div>
  );
};

Item.defaultProps = {
  history: undefined,
  jobId: undefined,
  userId: undefined,
  question: undefined,
  questionNumber: undefined,
  withButton: true,
  size: undefined,
  textSize: undefined,
  retakeAnswerAfterFileUpload: undefined,
  answer: {},
  questionTextProps: undefined,
  memorizedCompanyInfo: undefined,
  thinkingTime: 0,
  spentTime: 0
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  jobId: PropTypes.string,
  userId: PropTypes.string,
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string
  }),
  questionNumber: PropTypes.number,
  withButton: PropTypes.bool,
  size: PropTypes.string,
  textSize: PropTypes.string,
  retakeAnswerAfterFileUpload: PropTypes.func,
  answer: PropTypes.shape({
    friendly_name: PropTypes.string,
    media_extension: PropTypes.string,
    size: PropTypes.number,
    key: PropTypes.string.isRequired,
    remote_link: PropTypes.string
  }),
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired,
  status: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isShowcaseLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(Item));
