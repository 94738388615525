import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CandidateSidebarComments from "./CandidateSidebarComments";
import "./index.scss";

const CandidateSidebar = ({ isSidebarOpen }) => {
  if (!isSidebarOpen) return null;
  return (
    <div className="candidate__sidebar-container">
      { isSidebarOpen === "comment" ? <CandidateSidebarComments /> : null }
    </div>
  );
};

CandidateSidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isSidebarOpen: state?.jobs?.isSidebarOpen
});

export default connect(mapStateToProps)(CandidateSidebar);
