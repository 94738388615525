import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CandidateSidebarCommentItem from "./CandidateSidebarCommentItem";
import CandidateSidebarAddComment from "./CandidateSidebarAddComment";

const CandidateSidebarComments = ({ comments }) => (
  <div className="candidate__sidebar-comments">
    <div className="candidate__sidebar-comments__header">
      Comments
    </div>
    <CandidateSidebarAddComment />
    <div className="candidate__sidebar-commentlist">
      {comments?.map(comment => (
        <CandidateSidebarCommentItem key={comment.key} comment={comment} />
      ))}
    </div>
  </div>
);

CandidateSidebarComments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      author_email: PropTypes.string,
      author_name: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      updated_at: PropTypes.string
    })
  ).isRequired
};

const mapStateToProps = state => ({
  comments: state.sidebar.comments ?? []
});

export default connect(mapStateToProps)(CandidateSidebarComments);
