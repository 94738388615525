import React, { useEffect, useMemo, useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Close } from "mixins/svgIcons";
import { AUDIO_TYPE, DBS_RTW_TYPE, DBS_TYPE, FILE_TYPE, IDV_TYPE, MULTIPLE_CHOICE_TYPE, RTW_TYPE, TEXT_TYPE, VIDEO_TYPE } from "configs/jobs/constants";
import CandidateFileIcon from "assets/images/candidate/CandidateFileIcon";
import CandidateAudioIcon from "assets/images/candidate/CandidateAudioIcon";
import CandidateTextIcon from "assets/images/candidate/CandidateTextIcon";
import CandidateIDVIcon from "assets/images/candidate/CandidateIDVIcon";
import CandidateThumbnailVideo from "./CandidateThumbnail/CandidateThumbnailVideo";
import CandidateMultipleChoiceIcon from "./CandidateThumbnail/CandidateThumbnailMultipleChoice";

const CandidateAnswerPreview = ({
  nextAnswer,
  currentQuestionAnswer: {
    key
  } = {},
  timePlaying,
  handleNextQuestion,
  isHovering,
  setIsHovering,
  animated,
  setAnimated
}) => {
  const animationRef = useRef(null);
  const previewRef = useRef(null);

  const remainingTime = useMemo(() =>
    parseInt(timePlaying?.[key]?.remaining, 10) || 0, [key, timePlaying]);

  useEffect(() => {
    const animation = animationRef.current;

    if (animation && remainingTime <= 0.001 && animated) {
      setTimeout(() => {
        handleNextQuestion();
      }, [800]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRef, remainingTime, animated]);

  const handleCancel = () => {
    setAnimated(false);
    setIsHovering(false);
  };

  const answerIcon = {
    [FILE_TYPE]: <CandidateFileIcon />,
    [AUDIO_TYPE]: <CandidateAudioIcon />,
    [TEXT_TYPE]: <CandidateTextIcon />,
    [VIDEO_TYPE]: <CandidateThumbnailVideo height="60" width="60" />,
    [MULTIPLE_CHOICE_TYPE]: <CandidateMultipleChoiceIcon />,
    [IDV_TYPE]: <CandidateIDVIcon />,
    [RTW_TYPE]: <CandidateIDVIcon />,
    [DBS_RTW_TYPE]: <CandidateIDVIcon />,
    [DBS_TYPE]: <CandidateIDVIcon />
  };


  if (!nextAnswer || !animated) {
    return null;
  }

  return (
    <div
      className={`candidate-answer-preview__wrapper ${animated ? "animated" : ""}`}
      ref={animationRef}
      role="button"
      tabIndex={0}
      onClick={handleCancel}
      onKeyDown={handleCancel}
    >
      <div
        className="candidate-answer-preview__number"
        ref={previewRef}
      >
        { !isHovering ? remainingTime : <Close file="#FFF" /> }
      </div>
      <div className="candidate-answer-preview__type">
        {answerIcon?.[nextAnswer?.question?.answer_type] || nextAnswer?.question?.answer_type}
      </div>
    </div>
  );
};

CandidateAnswerPreview.defaultProps = {
  nextAnswer: {},
  currentQuestionAnswer: {},
  timePlaying: {},
  handleNextQuestion: () => {},
  isHovering: false,
  setIsHovering: () => {},
  animated: false,
  setAnimated: () => {}
};

CandidateAnswerPreview.propTypes = {
  nextAnswer: PropTypes.shape({
    question: PropTypes.shape({
      order: PropTypes.number,
      answer_type: PropTypes.string,
      text: PropTypes.string
    })
  }),
  currentQuestionAnswer: PropTypes.shape({
    question: PropTypes.shape({
      order: PropTypes.number
    })
  }),
  timePlaying: PropTypes.shape({
    remaining: PropTypes.number
  }),
  handleNextQuestion: PropTypes.func,
  isHovering: PropTypes.bool,
  setIsHovering: PropTypes.func,
  animated: PropTypes.bool,
  setAnimated: PropTypes.func
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerPreview));
