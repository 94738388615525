import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCandidateIdvResponse } from "store/modules/jobs/selectors";
import { withTranslation } from "react-i18next";
import { isNull } from "lodash";
import { ClipLoader } from "react-spinners";
import { IDV_OMITTED, IDVStatusChip, ALL_IDV_REJECTED_REASON } from "configs/jobs/constants";

const IDVIdentity = ({
  t,
  idvCheckData,
  isIdvSkipped,
  countryName,
  date,
  handleDownloadClick
}) => {
  const isIdentityProcessing = useMemo(() => (isNull(idvCheckData?.identity?.image_id) ||
    !idvCheckData?.identity) && !isIdvSkipped, [idvCheckData, isIdvSkipped]);

  const identityStatus = useMemo(() => (isIdvSkipped
    ? IDV_OMITTED
    : idvCheckData?.identity?.status), [idvCheckData, isIdvSkipped]);

  if (isIdentityProcessing) {
    return (
      <div className="candidateanswer__section__response-idv__document-processing">
        <ClipLoader color="#BBC2C9" size={18} />
        <div className="idv-check__container-title">
          <div className="idv-check__container-title-wrapper">
            {t("job.candidate.overview.idv.check.skipped")}
            <strong>
              {t("job.candidate.overview.idv.document.processing")}
            </strong>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="candidateanswer__section__response-idv__response-item">
      <div className="candidateanswer__section__response-idv__response-item__row title">
        <div className="candidateanswer__section__response-idv__response-item-title">
          {t("job.candidate.overview.idv.check.title")}
        </div>
        {IDVStatusChip[identityStatus]}
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        <p style={{ width: "75%" }}>
          {`${t("job.candidate.overview.idv.check.subtitle")} (${countryName ? `${countryName} ` : ""}${date || ""})`}
        </p>

      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        {Boolean(idvCheckData?.identity?.rejection_reason) && (
          <p>
            {ALL_IDV_REJECTED_REASON?.includes(idvCheckData?.identity?.rejection_reason)
              ? t(`job.candidate.overview.idv.rejection_reason.${idvCheckData?.identity?.rejection_reason}`)
              : idvCheckData?.identity?.rejection_reason}
          </p>
        )}
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        {document.images_id && !isIdentityProcessing ? (
          <button
            type="button"
            className="button__without-styles link_url"
            onClick={() =>
              handleDownloadClick("identity")
            }
          >
            {t("job.candidate.overview.idv.media")}
          </button>
        ) : null}
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        {idvCheckData?.identityImage && !isIdentityProcessing ? (
          <button
            type="button"
            className="button__without-styles link_url"
            onClick={() =>
              handleDownloadClick("identity")
            }
          >
            {t("job.candidate.overview.idv.media")}
          </button>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  idvCheckData: getCandidateIdvResponse(state)
});

export default connect(mapStateToProps)(withTranslation()(IDVIdentity));

IDVIdentity.defaultProps = {
  idvCheckData: {},
  countryName: "",
  date: "",
  isIdvSkipped: false,
  handleDownloadClick: () => { }
};

IDVIdentity.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    identity: PropTypes.shape({
      status: PropTypes.string,
      rejection_reason: PropTypes.string,
      image_id: PropTypes.string
    }),
    identityImage: PropTypes.string
  }),
  isIdvSkipped: PropTypes.bool,
  countryName: PropTypes.string,
  date: PropTypes.string,
  handleDownloadClick: PropTypes.func
};
