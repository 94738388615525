import PropTypes from "prop-types";
import React, { useState } from "react";

const DownloadIcon = ({ fill = "#5A2AF1" }) => {
  const [mouseOver, setMouseOver] = useState(false);

  fill = mouseOver ? "#5A2AF1" : fill;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => setMouseOver(false)}
    >
      <path d="M15.75 10.6875C16.9805 10.6875 18 11.707 18 12.9375V15.75C18 17.0156 16.9805 18 15.75 18H2.25C0.984375 18 0 17.0156 0 15.75V12.9375C0 11.707 0.984375 10.6875 2.25 10.6875H4.11328L5.80078 12.375H2.25C1.93359 12.375 1.6875 12.6562 1.6875 12.9375V15.75C1.6875 16.0664 1.93359 16.3125 2.25 16.3125H15.75C16.0312 16.3125 16.3125 16.0664 16.3125 15.75V12.9375C16.3125 12.6562 16.0312 12.375 15.75 12.375H12.1641L13.8516 10.6875H15.75ZM15.1875 14.3438C15.1875 14.8359 14.8008 15.1875 14.3438 15.1875C13.8516 15.1875 13.5 14.8359 13.5 14.3438C13.5 13.8867 13.8516 13.5 14.3438 13.5C14.8008 13.5 15.1875 13.8867 15.1875 14.3438ZM8.40234 12.9727L3.58594 8.19141C3.23438 7.875 3.23438 7.34766 3.58594 6.99609C3.90234 6.67969 4.42969 6.67969 4.78125 6.99609L8.15625 10.3711V0.84375C8.15625 0.386719 8.50781 0 9 0C9.45703 0 9.84375 0.386719 9.84375 0.84375V10.3711L13.1836 7.03125C13.5 6.67969 14.0273 6.67969 14.3438 7.03125C14.6953 7.34766 14.6953 7.875 14.3438 8.19141L9.5625 12.9727C9.42188 13.1484 9.21094 13.2188 9 13.2188C8.75391 13.2188 8.54297 13.1484 8.40234 12.9727Z" fill={fill} />
    </svg>

  );
};

DownloadIcon.propTypes = {
  fill: PropTypes.string.isRequired
};

export default DownloadIcon;
