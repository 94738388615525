import { handleActions } from "redux-actions";
import { setSidebarCommentsData, sidebarAddComment, sidebarRemoveComment } from "./actions";

const defaultState = {
  isLoading: false,
  comments: []
};

export default handleActions(
  {
    [setSidebarCommentsData]: (state, { payload }) => ({
      ...state,
      comments: payload
    }),
    [sidebarAddComment]: (state, { payload }) => ({
      ...state,
      comments: [payload, ...state.comments]
    }),
    [sidebarRemoveComment]: (state, { payload }) => ({
      ...state,
      comments: state.comments.filter(a => a.key !== payload)
    })
  },
  defaultState
);
