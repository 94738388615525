/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import { Question, TimerIcon } from "mixins/svgIcons";
import { spentTimeString } from "mixins/helperVideoRecording";
import TooltipInfo from "components/Common/Tooltip/Info";
import ReactHtmlParser from "react-html-parser";

import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import "./styles.scss";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import { connect } from "react-redux";
import { Up, Down } from "./svgIcons";

const AnswerHeader = ({
  t,
  questionNumber,
  thinkingTime,
  spentTime,
  textSize,
  questionTextProps,
  currentQuestion,
  questionText,
  isJobCandidateLoading,
  isShowcaseLoading
}) => {
  const divRef = useRef(null);
  const textRef = useRef(null);
  const [isShown, setIsShown] = useState(false);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  const checkIsEllipsisActive = e => {
    const c = e.cloneNode(true);
    c.style.webkitLineClamp = "inherit";
    c.style.maxHeight = "100%";
    divRef.current.appendChild(c);
    const truncated = c.offsetHeight > e.offsetHeight;
    c.remove();
    return truncated;
  };

  useLayoutEffect(() => {
    if (textRef.current) {
      setIsEllipsisActive(checkIsEllipsisActive(textRef.current));
    }
  }, []);

  return (
    <div
      onMouseEnter={isEllipsisActive ? () => setIsShown(true) : null}
      onMouseLeave={isEllipsisActive ? () => setIsShown(false) : null}
      className={`answer__header-hover ${isShown ? "active-hover" : ""}`}
    >
      <div ref={divRef} className="answer__header-container">
        <div className="video__preview-item-flex">
          <SkeletonWrapper
            skeletonProps={{
              count: 1,
              height: 16,
              width: 180,
              enableAnimation: true
            }}
            skeletonWrapperProps={{
              style: {
                marginBottom: 15
              }
            }}
            isLoading={isJobCandidateLoading || isShowcaseLoading}
          >
            <p className="video__preview-item-suptitle">
              <Question svgIconClass="video__preview-item-icon" />
              <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
            </p>
          </SkeletonWrapper>
          {thinkingTime ? (
            <TooltipInfo
              position="bottom"
              message={t("candidate.common.tooltipThinking")}
            >
              <p
                className={`thinking n-font-medium-weight n-black-80 ${
                  thinkingTime < spentTime ? "amber" : "green"
                }`}
              >
                <TimerIcon />
                <span>
                  {`${spentTimeString(spentTime)} / ${spentTimeString(
                    thinkingTime
                  )}`}
                </span>
              </p>
            </TooltipInfo>
          ) : null}
        </div>
        <h3
          ref={textRef}
          className={`video__preview-item-title ${textSize}`}
          {...questionTextProps}
        >
          <SkeletonWrapper
            skeletonProps={{
              count: 1,
              height: 16,
              width: 250,
              enableAnimation: true
            }}
            isLoading={isJobCandidateLoading || isShowcaseLoading}
          >
            <>
              {currentQuestion?.question?.html_text
                ? ReactHtmlParser(currentQuestion?.question?.html_text)
                : questionText}
            </>
          </SkeletonWrapper>
        </h3>
        {isMobile && isEllipsisActive ? (
          isShown ? (
            <button
              className="button__without-styles up"
              onClick={() => setIsShown(false)}
              type="button"
            >
              <Up />
            </button>
          ) : (
            <button
              className="button__without-styles down"
              onClick={() => setIsShown(true)}
              type="button"
            >
              <Down />
            </button>
          )
        ) : null}
      </div>
    </div>
  );
};

AnswerHeader.defaultProps = {
  thinkingTime: 0,
  spentTime: 0,
  textSize: "",
  questionText: "",
  isJobCandidateLoading: true,
  isShowcaseLoading: true
};

AnswerHeader.propTypes = {
  t: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  textSize: PropTypes.string,
  questionTextProps: PropTypes.shape({}).isRequired,
  questionText: PropTypes.string,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired,
  isJobCandidateLoading: PropTypes.bool,
  isShowcaseLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isShowcaseLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(AnswerHeader));
