/* eslint-disable react/button-has-type */
import CommentsIcon from "assets/images/candidate/CommentsIcon";
import TextIcon from "assets/images/candidate/TextIcon";
import TranscriptionIcon from "assets/images/candidate/TranscriptionIcon";
import React from "react";
import PropTypes from "prop-types";
import DownloadIcon from "assets/images/candidate/DownloadIcon";
import { getCandidateIdvResponse } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import { ALL_IDENTITY_VERIFICATION_TYPES } from "configs/jobs/constants";

const CandidateWidget = ({
  setOpenSidebar,
  activeSidebar,
  currentQuestionAnswer: {
    remote_link: remoteLink,
    question: {
      answer_type: answerType
    } = {}
  } = {},
  currentQuestionAnswer,
  idvCheckData
}) => (
  <div className="candidatewidget">
    <button
      className="flex items-center justify-center"
      onClick={() => setOpenSidebar(activeSidebar === "transcription" ? null : "transcription")}
    >
      <TranscriptionIcon
        fill={activeSidebar === "transcription" ? "#5A2AF1" : "#59595980"}
      />
    </button>
    <button
      className="flex items-center justify-center"
      onClick={() => setOpenSidebar(activeSidebar === "summary" ? null : "summary")}
    >
      <TextIcon
        fill={activeSidebar === "summary" ? "#5A2AF1" : "#59595980"}
      />
    </button>
    <button
      className="flex items-center justify-center"
      onClick={e => {
        e.stopPropagation();
        const link = ALL_IDENTITY_VERIFICATION_TYPES.includes(answerType)
          ? idvCheckData?.pdfUrl : remoteLink;
        window.open(link, "_blank");
      }}
      disabled={ALL_IDENTITY_VERIFICATION_TYPES.includes(answerType)
        ? !idvCheckData?.pdfUrl : !currentQuestionAnswer?.remote_link?.length
      }
    >
      <DownloadIcon
        fill={activeSidebar === "download" ? "#5A2AF1" : "#59595980"}
      />
    </button>
    <button
      className="flex items-center justify-center"
      onClick={() => setOpenSidebar((activeSidebar === "comment" ? null : "comment"))}
    >
      <CommentsIcon
        fill={activeSidebar === "comment" ? "#5A2AF1" : "#59595980"}
      />
    </button>
  </div>
);

CandidateWidget.defaultProps = {
  setOpenSidebar: () => {},
  activeSidebar: ""
};

CandidateWidget.propTypes = {
  setOpenSidebar: PropTypes.func,
  activeSidebar: PropTypes.string
};

const mapStateToProps = state => ({
  idvCheckData: getCandidateIdvResponse(state)
});

export default connect(mapStateToProps)(CandidateWidget);
