import React, { useMemo, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ANSWER_STATUS, FILE_TYPE } from "configs/jobs/constants";
import { withTranslation } from "react-i18next";
import DownloadAllButton from "./DownloadAllButton";

const DownloadAllButtonWrapper = ({
  t,
  realtimeAnswers,
  candidateAnswers,
  handleDownload
}) => {
  const overlayRef = useRef(null);

  const shouldShowDownloadAll = useMemo(() => {
    let isValid = true;
    // eslint-disable-next-line array-callback-return
    Object.keys(realtimeAnswers || {}).map(key => {
      const apiAnswer = candidateAnswers?.find(a => a.key === key);

      if (
      [ANSWER_STATUS.processed, ANSWER_STATUS.failed]?.includes(apiAnswer?.status) ||
      [ANSWER_STATUS.processed, ANSWER_STATUS.failed]?.includes(realtimeAnswers?.[key]?.status) ||
      (ANSWER_STATUS.received && apiAnswer?.is_skipped) ||
      ((realtimeAnswers?.[key]?.status === ANSWER_STATUS.received ||
        realtimeAnswers?.[key]?.status === ANSWER_STATUS.unsafe) &&
        apiAnswer?.question?.answer_type === FILE_TYPE)
      ) {
        return;
      }

      isValid = false;
    });

    return isValid;
  }, [candidateAnswers, realtimeAnswers]);

  return (
    <>
      {
        shouldShowDownloadAll ? (
          <OverlayTrigger
            ref={overlayRef}
            placement="bottom"
            delay={{ show: 250, hide: 100 }}
            popperConfig={{
              modifiers: {
                preventOverflow: {
                  enabled: false
                }
              }
            }}
            overlay={props => (
              <Tooltip className="tooltip-bottom__custom" {...props}>
                {t("job.candidate.download.disabledTooltip")}
              </Tooltip>
            )}
          >
            <DownloadAllButton
              shouldShowDownloadAll={shouldShowDownloadAll}
              handleDownload={handleDownload}
            />
          </OverlayTrigger>
        ) : (
          <DownloadAllButton
            shouldShowDownloadAll={shouldShowDownloadAll}
            handleDownload={handleDownload}
          />
        )
      }
    </>
  );
};

export default withTranslation()(DownloadAllButtonWrapper);
