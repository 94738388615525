/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Question } from "mixins/svgIcons";

const MultipleChoiceThumbnail = ({
  t,
  questionNumber,
  questionText,
  isCurrent,
  setCurrentQuestion,
  buttonColor,
  isRadio,
  answer,
  isRecruiter
}) => {
  const selectedAnswersIds =
  answer?.type_specific_data?.selected_answer_options?.map(a => a.option);


  const status = useMemo(() => {
    let isAllCorrect = true;
    let incorrectCount = 0;
    answer?.type_specific_data?.selected_answer_options?.forEach(a => {
      if (!a.is_correct) {
        isAllCorrect = false;
        incorrectCount += 1;
      }
    });

    const correctSelectedAnswers = [];
    const correctUnselectedAnswers = [];
    const incorrectSelectedAnswers = [];


    answer?.question?.type_specific_data?.answer_options?.forEach(a => {
      if (a.is_correct) {
        if (selectedAnswersIds?.includes(a.key)) {
          correctSelectedAnswers.push(a);
        } else {
          correctUnselectedAnswers.push(a);
        }
      } else if (selectedAnswersIds?.includes(a.key)) {
        incorrectSelectedAnswers.push(a);
      }
    });

    const correctOptions =
    answer?.question?.type_specific_data?.answer_options?.filter(a => a.is_correct);

    if (correctOptions.length === 0) {
      return "";
    }
    if ((correctSelectedAnswers?.length > 0
       && incorrectSelectedAnswers?.length > 0)) {
      return "Inconclusive";
    }

    if (correctSelectedAnswers?.length > 0 && correctUnselectedAnswers?.length >= 0 && incorrectSelectedAnswers?.length === 0) {
      return "Correct";
    }

    if ((incorrectCount > 0)) {
      return "Incorrect";
    }

    if (isAllCorrect) {
      return "Correct";
    }

    if (!isAllCorrect) {
      return "Inconclusive";
    }

    return "";
  }, [answer.question.type_specific_data.answer_options, answer.type_specific_data.selected_answer_options, selectedAnswersIds]);
  return (
    <div
      role="presentation"
      className={`preview__list-item ${isCurrent && "current"}`}
      onClick={setCurrentQuestion}
      style={isCurrent && buttonColor ? { background: `${buttonColor}20` } : null}
    >
      <div className="preview__list-item-type file">
        <input
          type={isRadio ? "radio" : "checkbox"}
          style={{ accentColor: "gray", pointerEvents: "none" }}
          checked
        />
        <p className="preview__list-item-type-name n-grey n-font-medium text">
          {isRadio ? "Multiple Choice" : "Checkboxes"}
        </p>
        {
          isRecruiter && status !== "" &&
          <p
            className={`people__table-status-title n-font-extra-small n-font-regular ${status === "Correct" ? "Accepted" : status === "Incorrect" ? "Rejected" : status === "Inconclusive" ? "Inconclusive" : ""}`}
          >
            {status}
          </p>
        }
        <p />
      </div>
      <div className="preview__list-item-info">
        <p className="preview__list-item-info-subtitle n-grey-100 n-font-small">
          <Question svgIconClass="preview__list-item-info-icon" />
          <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
        </p>

        <p
          className="preview__list-item-info-title n-grey-300 n-font-small"
          title={questionText}
        >
          {questionText}
        </p>
      </div>
    </div>
  );
};

MultipleChoiceThumbnail.propTypes = {
  t: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    type_specific_data: PropTypes.shape({
      selected_answer_options: PropTypes.arrayOf(PropTypes.shape({
        is_correct: PropTypes.bool
      }))
    }),
    question: PropTypes.shape({
      type_specific_data: PropTypes.shape({
        answer_options: PropTypes.arrayOf(PropTypes.shape({
          is_correct: PropTypes.bool
        }))
      })
    })
  }).isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  isRadio: PropTypes.bool.isRequired,
  isRecruiter: PropTypes.bool.isRequired
};

export default withTranslation()(MultipleChoiceThumbnail);
