import React, { useEffect } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import { ChevronRight } from "mixins/svgIcons";
import useWindowResize from "hooks/useWindowResize.tsx";

const ArrowButtonRight = ({
  currentIndex,
  nextIndex,
  items,
  setSelected
}) => {
  const { isMobileAndTabletScreen } = useWindowResize();

  const prevIndex = Math.max(currentIndex - 1, 0);

  const handleKeyDown = debounce(e => {
    if (!items.length) return;
    if (e.keyCode === 37) {
      setSelected(items[prevIndex]?.key);
    } else if (e.keyCode === 39) {
      setSelected(items[nextIndex]?.key);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <>
      <button
        type="button"
        className="button__without-styles arrow-button right"
        onClick={() => setSelected(items[nextIndex].key)}
        disabled={[-1, items.length - 1].includes(currentIndex)}
        title={currentIndex === items.length - 1 ? null : "next"}
      >
        <ChevronRight
          fill={[-1, items.length - 1].includes(currentIndex) ? "#595959" : "#5A2AF1"}
          height={isMobileAndTabletScreen ? "20" : "24"}
          width={isMobileAndTabletScreen ? "20" : "24"}
        />
      </button>
    </>
  );
};

ArrowButtonRight.defaultProps = {
  items: [],
  setSelected: null
};

ArrowButtonRight.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nextIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })),
  setSelected: PropTypes.func
};

export default ArrowButtonRight;
